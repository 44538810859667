export default {
  // Base url path
  // baseUrl: 'http://localhost:4000/nodeapp', // MI MAQUINA
  // baseUrl: 'http://192.168.150.62:4000/nodeapp', // Sebastian Maquina
  // baseUrl: 'http://192.168.150.18:4000/nodeapp', // Andrei Maquina
  // baseUrl: 'http://192.168.150.18:4000/nodeapp', // Andrei Maquina
  baseUrl: 'https://ap-api.mycaretrackhealth.com/agent_performance_prod_api',
  // Endpoints
  loginEndpoint: '/api/v1/auth/login',
  verifyAuthyCodeEndpoint: '/api/v1/users/verify',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  /* uploadFile: '/api/v1/lists/upload-files',
  getCounts: '/api/v1/lists/get-counts',
  getIpaTemplates: '/api/v1/lists/get-ipa-templates',
  getSelectedIpaTemplate: '/api/v1/lists/get-selected-ipa-template',
  setDefaultTemplate: '/api/v1/lists/set-default-template',
  createIpaTemplate: '/api/v1/lists/add-ipa-template',
  getFilesDateCounts: '/api/v1/lists/get-files-date-counts',
  getApptHistory: '/api/v1/appointments/get-appt-history',
  getUsersLogged: 'https://beta.mycaretrackhealth.com/vista/reportes/loginRegister.php', */

  // Endpoints Agent Performance
  signUp: '/api/v1/auth/signup',
  updateUser: '/api/v1/auth/update-user',
  deleteUser: '/api/v1/auth/delete-user',
  getUserById: '/api/v1/auth/get-user',
  createCandidate: '/api/v1/auth/create-candidate',
  updateCandidate: '/api/v1/auth/update-candidate',
  exportCandidateTracking: '/api/v1/auth/export-candidate-tracking',

  // Candidates
  updateStatusCandidate: '/api/v1/users/update-candidate-status',
  // baseUrlFiles: 'http://192.168.150.18:4000', // local
  baseUrlFiles: 'https://ap-api.mycaretrackhealth.com/agent_performance_prod_api', // produccion

  getProfiles: '/api/v1/users/get-users',
  getSimpleUserInfo: '/api/v1/users/get-simple-user-info',
  getAdvancedUserInfo: '/api/v1/users/get-advanced-user-info',
  getAllSupervisor: '/api/v1/users/get-all-supervisors',
  getHistoryOfSupervisorRecord: '/api/v1/logs/get-supervisor-changes',

  getEvalutionsByCategory: '/api/v1/evaluations/get-evaluations',
  getEvaluationBasicInfo: '/api/v1/evaluations/get-evaluation-basic-info',
  getEvaluationUserMetrics: '/api/v1/evaluations/get-evaluation-user-metrics',
  createEvaluation: '/api/v1/evaluations/create-evaluation',
  getEvaluationById: '/api/v1/evaluations/get-evaluation-application',
  updateEvaluation: '/api/v1/evaluations/update-evaluation',
  getEvaluationStaff: '/api/v1/users/get-evaluations-staff',
  getEvaluationApplication: '/api/v1/evaluations/get-evaluation-application',
  ApplyEvaluation: '/api/v1/users/save-evaluation-application',
  getUserEvaluations: '/api/v1/users/get-user-evaluations',
  getUserAvgHeadingScore: '/api/v1/users/get-user-avg-question-score',
  getUserEvaluationDetails: '/api/v1/users/get-user-evaluation-details',
  updateUserEvaluation: '/api/v1/users/update-user-evaluation',

  getAllSections: '/api/v1/sections/get-sections',
  createSection: '/api/v1/sections/create-section',
  updateSection: '/api/v1/sections/update-section',
  getSection: '/api/v1/sections/get-section',
  deleteSection: '/api/v1/sections/delete-section',

  getCategories: '/api/v1/categories/get-categories',
  insertCategory: '/api/v1/categories/create-category',

  getAllStaff: '/api/v1/staff/get-all-staff',
  createStaff: '/api/v1/staff/create-staff',

  getAppliedEvaluationsChanges: '/api/v1/logs/get-applied-evaluations-changes',

  getPayrollIndividual: '/api/v1/payroll/get-payroll-individual',
  getAllUsers: '/api/v1/users/get-all-users',
  getBreakDownPayroll: '/api/v1/payroll/get-breackdown-payroll',
  getCheckInOutDay: '/api/v1/payroll/get-checkinout-day',
  createCheckInOut: '/api/v1/payroll/create-checkintout',
  exportDataExcel: '/api/v1/payroll/get-payroll-data-excel',

  createElementCatalog: '/api/v1/catalogs/create-element',
  getAllElementCatalog: '/api/v1/catalogs/get-all',
  getSpecificElementCatalog: '/api/v1/catalogs/get-one',
  editSpecificElementCatalog: '/api/v1/catalogs/edit-element',
  deleteSpecificElementCatalog: '/api/v1/catalogs/delete-element',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
